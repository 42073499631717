import { render, staticRenderFns } from "./OutsourcingsSummary.vue?vue&type=template&id=89fb10ce"
import script from "./OutsourcingsSummary.vue?vue&type=script&lang=js"
export * from "./OutsourcingsSummary.vue?vue&type=script&lang=js"
import style0 from "./OutsourcingsSummary.vue?vue&type=style&index=0&id=89fb10ce&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports